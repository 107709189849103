import React, { Component } from "react";
import PrimarySearchAppBar from './PrimarySearchAppBar'
import { Switch, Route } from "react-router-dom";
import Home from './Home'
import About from './About'
import Services from './Services'
import Contact from './Contact'
import Footer from './Footer'
import Pricing from './Pricing'
import Privacy from "./Privacy";
import ProfessionalServices from './landing-pages/ProfessionalServices';
import RegularLodgeAttForm from "./freemasons/RegularLodgeAttForm";
import CookieConsent from "react-cookie-consent";
import Analytics from 'react-router-ga'

class Main extends Component {
  render() {
    return (
      <div>
        <Analytics id="UA-153402610-2">
          <PrimarySearchAppBar />
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/about-us' exact component={About} />
            <Route path='/services' exact component={Services} />
            <Route path='/contact' exact component={Contact} />
            <Route path='/pricing' exact component={Pricing} />
            <Route path='/privacy-policy' exact component={Privacy} />
            <Route path='/pay-monthly-web-design' exact component={Pricing} />
            <Route path='/freemasons-form' exact component={RegularLodgeAttForm} />
            <Route path='/professional-services-website' exact component={ProfessionalServices} />
          </Switch>

          <Footer />
          <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
        </Analytics>
      </div>
    )
  }
}

export default Main;