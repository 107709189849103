import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography'
import CssBaseline from '@material-ui/core/CssBaseline';


const useStyles = makeStyles( (theme) => ({
  'global': {

  },
  heroContent: {
    padding: theme.spacing(8, 0, 6)
  },
  headingContent: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(8),
  }
}))


export default function ProfessionalServices() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="Bee Frameworks offers bespoke website design and development services tailored
    for professional services such as accountants, lawyers, and consultants. Get a fully
    responsive, SEO-optimized website to grow your business online."
        />
        <meta
          name="keywords"
          content="professional services websites, accountant websites, lawyer websites,
    website design for consultants, SEO optimized websites, bespoke web development,
    responsive websites, Bee Frameworks"
        />
        <meta
          name="author"
          content="Bee Frameworks"
        />
        <meta
          property="og:title"
          content="Professional Services Website Design - Bee Frameworks"
        />
        <meta
          property="og:description"
          content="Get custom-designed, SEO-friendly websites for professional services like
    accounting, legal, and consulting businesses. Boost your online presence with
    Bee Frameworks."
        />
        <meta
          property="og:type"
          content="website"
        />
        <meta
          property="og:url"
          content="https://www.beeframeworks.com/professional-services-website"
        />
        <meta
          name="twitter:title"
          content="Professional Services Website Design - Bee Frameworks"
        />
        <meta
          name="twitter:description"
          content="Custom website design and development for accountants, lawyers, and consultants.
    Bee Frameworks helps grow your professional services business online."
        />
        <title>Professional Services Website Design | Bee Frameworks</title>
      </Helmet>
      <CssBaseline />
      {/*Hero unit*/}
      <Container className={classes.heroContent} component="main" maxWidth="sm">
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="textPrimary"
          className={classes.headingContent}
          gutterBottom>
          Take Your Professional Services Business Online Today
        </Typography>
        <Typography
          variant="body1"
          align="center"
          style={{ fontStyle: 'italic' }}>
          Build trust, attract clients, and grow your business with a professional, custom design website.
        </Typography>
        <br/>
        <Typography align="center">
          As a business offering professional services—whether you're an accountant, lawyer, consultant,
          or any other expert—your online presence is key to attracting new clients and establishing credibility.
          We specialize in creating websites tailored to businesses like yours,
          helping you showcase your expertise and build strong client relationships.
        </Typography>
      </Container>
      <Container maxWidth="md" component="main">

      </Container>
    </React.Fragment>
  )

}