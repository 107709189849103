import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";

export default function DynamicMenuOptions({ label, options, selectedValue, handleSelection, step }) {
  // Filter out null or undefined values
  const filteredOptions = options.filter(option => option !== null && option !== undefined);

  return (
    <div className="mason-question">
      {/*<div style={{ marginBottom: "20px" }}>*/}
      {/*  <Typography*/}
      {/*    variant="h7">*/}
      {/*    {label}*/}
      {/*  </Typography>*/}
      {/*</div>*/}
      {filteredOptions.length > 0 ? (
        <FormControl component="fieldset">
          <RadioGroup
            name={`${label}-radio-group`}
            value={selectedValue}
            onChange={(e) => handleSelection(e, step)}
          >
            {filteredOptions.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option}
                control={<Radio />}
                label={option}
              />
            ))}
          </RadioGroup>
        </FormControl>
      ) : (
        <Typography variant="body2" style={{ color: "gray" }}>
          No options available.
        </Typography>
      )}
    </div>
  );
}
