import React, { useState, useEffect } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {Link} from "react-router-dom";
import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import {Helmet} from "react-helmet";
import CssBaseline from "@material-ui/core/CssBaseline";
import {makeStyles} from "@material-ui/core/styles";
import wallPaper from "../assets/static/images/OMtmkr.jpg";


const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(14, 0, 6),
    backgroundImage: `url(${wallPaper})`,
    backgroundColor: 'rgba(0, 0, 0, 0.13)',
    backgroundBlendMode: 'darken',
    backgroundSize: 'cover'
  }
}));

export default function Privacy(props) {

  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [isLoaded, setIsLoaded] = useState(false);

  return (
  <React.Fragment>
    <Helmet>
    <meta name="description"
          content="Privacy Policy" />
    <title>Bee Frameworks - Privacy</title>
    </Helmet>
    <CssBaseline />
    <main>
    {/* Hero unit */}
    <div className={classes.heroContent}>
    <Breadcrumb>
      <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
      <BreadcrumbItem active>Privacy</BreadcrumbItem>
    </Breadcrumb>
      <Container maxWidth="sm">
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="textPrimary"
          style={{fontWeight: 'bold'}}
          gutterBottom>
          <span style={{backgroundColor: 'rgb(245, 195, 0)', padding: '0.0em 0.2em 0.0em 0.2em'}}>Privacy Policy</span>
        </Typography>
      </Container>
    </div>
    </main>
    <div className='container'>
      <div>
        <Typography variant="subtitle1" component="div" style={{padding: '20px'}}>
          <h2>Privacy Policy</h2>
          <p><strong>Effective Date:</strong> March 1, 2025</p>
          <p>Welcome to Bee Frameworks, a provider of innovative software solutions. We respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, share, and safeguard your data when you visit our website, use our services, or interact with our products (collectively, “Services”). By using our Services, you agree to this policy. If you don’t agree, please don’t use our Services.</p>
          <p>Here’s a quick summary: We collect your name, contact info, and usage data to provide and improve our Services. We don’t sell your data, use reasonable security measures to protect it, and give you choices about how it’s used. Read on for the full details.</p>

          <h3>1. Information We Collect</h3>
          <p>We collect the following types of information:</p>
          <ul>
            <li><strong>Information You Provide:</strong> Name, email address, phone number, and other contact details. Account login credentials (if applicable). Payment and billing information (processed securely through third-party providers like Stripe). Anything you share through forms, customer support, or feedback (e.g., survey responses, messages).</li>
            <li><strong>Information We Collect Automatically:</strong> IP address, device type, browser, and operating system. Usage data, such as pages visited, time spent, clicks, and feature interactions. Data from cookies and similar tracking tools (see Section 6).</li>
            <li><strong>Information from Third Parties:</strong> Data from partners like social media platforms (if you log in via them). Insights from analytics providers (e.g., Google Analytics) or advertising networks (e.g., Facebook Pixel).</li>
          </ul>

          <h3>2. How We Use Your Information</h3>
          <p>We use your data to:</p>
          <ul>
            <li>Provide, maintain, and improve our Services and products.</li>
            <li>Personalize your experience and offer tailored content or features.</li>
            <li>Process transactions and manage customer relationships.</li>
            <li>Send service-related updates (e.g., product changes) and, with your consent, promotional offers or newsletters.</li>
            <li>Analyze usage trends to develop new features and improve performance.</li>
            <li>Ensure security, prevent fraud, and comply with legal obligations.</li>
          </ul>

          <h3>3. How We Share Your Information</h3>
          <p>We don’t sell or rent your personal data. We may share it in these cases:</p>
          <ul>
            <li><strong>Service Providers:</strong> With trusted vendors (e.g., AWS for hosting, Mailchimp for emails) who are contractually bound to protect your data.</li>
            <li><strong>Legal Compliance:</strong> When required by law, such as responding to court orders or law enforcement requests.</li>
            <li><strong>Business Transfers:</strong> If we’re involved in a merger, acquisition, or sale, your data may be transferred.</li>
            <li><strong>Aggregated Data:</strong> We may share anonymized data (e.g., usage stats) for research or marketing, but it won’t identify you.</li>
          </ul>

          <h3>4. How We Protect Your Information</h3>
          <p>We take reasonable steps to protect your data, including:</p>
          <ul>
            <li>Encryption of sensitive data (e.g., payment details) using protocols like TLS during transmission and storage.</li>
            <li>Access controls and authentication to limit who can see your data.</li>
            <li>Regular security audits and staff training on data protection.</li>
          </ul>
          <p>No system is 100% secure, so we can’t guarantee absolute protection. If a breach occurs, we’ll notify affected users promptly as required by law. You’re responsible for keeping your account credentials safe.</p>

          <h3>5. Your Rights and Choices</h3>
          <p>Depending on your location (e.g., EU, California), you may have the right to:</p>
          <ul>
            <li>Access, update, or delete your personal data.</li>
            <li>Opt out of marketing emails (via the unsubscribe link in emails).</li>
            <li>Restrict or object to certain data processing.</li>
            <li>Request a copy of your data in a portable format.</li>
          </ul>
          <p>To exercise these rights, email us at <a href="mailto:support@beeframeworks.com">support@beeframeworks.com</a>. We’ll respond within 30 days, or as required by law. You can also opt out of analytics tracking via tools listed in Section 6.</p>

          <h3>6. Cookies and Tracking Technologies</h3>
          <p>We use cookies and similar tools to improve your experience, analyze trends, and show targeted ads. We’ll ask for your consent where required by law (e.g., GDPR regions).</p>
          <p>If you disable cookies, some features may not work properly.</p>

          <h3>7. Third-Party Links and Services</h3>
          <p>Our Services may link to third-party websites or tools. We’re not responsible for their privacy practices, so please review their policies before sharing data with them.</p>

          <h3>8. Children’s Privacy</h3>
          <p>Our Services aren’t designed for children under 13 (or the applicable age in your region). We don’t knowingly collect their data. If you think a child has shared info with us, contact us at <a href="mailto:support@beeframeworks.com">support@beeframeworks.com</a>, and we’ll delete it promptly.</p>

          <h3>9. Data Retention</h3>
          <p>We keep your data only as long as needed to provide our Services or meet legal requirements. Afterward, we securely delete or anonymize it. For example, account data is retained while active, and usage data may be kept for up to 3 years for analytics.</p>

          <h3>10. International Data Transfers</h3>
          <p>If you’re outside the U.S., your data may be transferred to and stored in the U.S. or other countries. We use safeguards like Standard Contractual Clauses to protect it during these transfers.</p>

          <h3>11. Changes to This Privacy Policy</h3>
          <p>We may update this policy from time to time. We’ll post changes here with a new “Effective Date” and notify you of significant updates (e.g., via email or a website notice). Your continued use of our Services after changes means you accept them.</p>

          <h3>12. Contact Us</h3>
          <p>Have questions or concerns? Reach out to us:</p>
          <p>Email: <a href="mailto:support@beeframeworks.com">support@beeframeworks.com</a></p>
        </Typography>
      </div>
    </div>
  </React.Fragment>
  );
}