import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from '@material-ui/core/Divider';

export default function DynamicGuestForm({
    numGuests,
    setNumGuests,
    guestDetails,
    setGuestDetails,
    menuOptions
  }) {

  // Handle radio button selection
  const handleGuestSelection = (event) => {
    const numberOfGuests = parseInt(event.target.value, 10);
    setNumGuests(numberOfGuests);

    if (numberOfGuests > 0) {
      setGuestDetails(
        Array.from({ length: numberOfGuests }, (_, index) => ({
          name: guestDetails[index]?.name || '',
          rank: guestDetails[index]?.rank || '',
          providingOption: guestDetails[index]?.providingOption || null,
          starter: guestDetails[index]?.starter || '',
          main: guestDetails[index]?.main || '',
          dessert: guestDetails[index]?.dessert || ''
        }))
      );
    } else {
      setGuestDetails([]);
    }
  };

  const handleGuestDetailChange = (index, field, value) => {
    const updatedGuests = [...guestDetails];
    updatedGuests[index][field] = value;
    setGuestDetails(updatedGuests);
  };

  const handleGuestOptionChange = (index, value) => {
    const updatedGuests = [...guestDetails];
    updatedGuests[index].providingOption = value === 'Yes';
    setGuestDetails(updatedGuests);
  };

  return (
    <div className="mason-question">
      <div style={{ marginBottom: "20px", fontStyle: "italic" }}>
        <Typography variant="h7">Please specify the name and rank of your guest, if applicable.</Typography>
      </div>
      <FormControl component="fieldset">
        <RadioGroup
          name="guest-count"
          value={numGuests}
          onChange={handleGuestSelection}
        >
          {[0, 1, 2, 3, 4, 5].map((num) => (
            <FormControlLabel key={num} value={num} control={<Radio />} label={`${num} Guest${num !== 1 ? 's' : ''}`} />
          ))}
        </RadioGroup>
      </FormControl>

      {numGuests > 0 && (
        <div>
          <div style={{ marginBottom: "20px", fontWeight: "bold", marginTop: '30px' }}>
            <Typography variant="h7">Guest Details:</Typography>
          </div>
          {guestDetails.map((guest, index) => (
            <Grid container spacing={2} key={index} style={{ marginBottom: '40px' }}>
              <Grid item xs={6}>
                <TextField
                  label={`Guest ${index + 1} Name`}
                  variant="outlined"
                  fullWidth
                  value={guest.name}
                  onChange={(e) => handleGuestDetailChange(index, 'name', e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={`Guest ${index + 1} Rank`}
                  variant="outlined"
                  fullWidth
                  value={guest.rank}
                  onChange={(e) => handleGuestDetailChange(index, 'rank', e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ marginTop: '10px', fontWeight: 'bold' }}>
                  Are you providing a meal option for this guest? ({guest.name})
                </div>
                <FormControl>
                  <RadioGroup
                    name={`meal-option-${index}`}
                    value={guest.providingOption === true ? 'Yes' : guest.providingOption === false ? 'No' : ''}
                    onChange={(e) => handleGuestOptionChange(index, e.target.value)}
                  >
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {guest.providingOption && (
                <>
                  <Grid item xs={12}>
                    <div style={{ marginTop: '10px', fontWeight: 'bold' }}>
                      Please select the meal options for {guest.name}:
                    </div>
                  </Grid>

                  <Grid item xs={4}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Starter</InputLabel>
                      <Select
                        value={guest.starter || ""}
                        onChange={(e) => handleGuestDetailChange(index, 'starter', e.target.value)}
                        label="Starter"
                      >
                        <MenuItem value="">
                          <em>Select Starter</em>
                        </MenuItem>
                        {menuOptions.map((option, i) => (
                          <MenuItem key={i} value={option['Starter Option']}>
                            {option['Starter Option']}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Main</InputLabel>
                      <Select
                        value={guest.main || ""}
                        onChange={(e) => handleGuestDetailChange(index, 'main', e.target.value)}
                        label="Main"
                      >
                        <MenuItem value="">
                          <em>Select Main</em>
                        </MenuItem>
                        {menuOptions.map((option, i) => (
                          <MenuItem key={i} value={option['Mains Options']}>
                            {option['Mains Options']}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Dessert</InputLabel>
                      <Select
                        value={guest.dessert || ""}
                        onChange={(e) => handleGuestDetailChange(index, 'dessert', e.target.value)}
                        label="Dessert"
                      >
                        <MenuItem value="">
                          <em>Select Dessert</em>
                        </MenuItem>
                        {menuOptions.map((option, i) => (
                          <MenuItem key={i} value={option['Desert Options']}>
                            {option['Desert Options']}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
              <div style={{ width: '100%', padding: '30px', marginTop: '20px'}}>
                <Divider variant="middle" />

              </div>
            </Grid>
          ))}
        </div>
      )}
    </div>
  );
}
