import * as React from 'react'
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

export default function PaymentQ({handlePaymentQ, paymentQValue}) {
  return (
    <div className="mason-question">
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={paymentQValue}
          onChange={(e) => handlePaymentQ(e, 7)}
        >
          <FormControlLabel value="Transfer" control={<Radio />} label="Transfer" />
          <FormControlLabel value="Cash" control={<Radio />} label="Cash" />
        </RadioGroup>
      </FormControl>
    </div>
  )
}