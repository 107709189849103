import * as React from 'react'
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";

export default function Q1({handleQ, q1Value, dateLabel}) {
  return (
    <div className="mason-question">
      <div style={{marginBottom: "20px", fontStyle: "italic"}}>
        <Typography variant="h7" >
         Due to be held on {dateLabel}
        </Typography>
      </div>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={q1Value}
          onChange={(e) => handleQ(e, 1)}
        >
          <FormControlLabel value="Yes" control={<Radio/>} label="Yes"/>
          <FormControlLabel value="No" control={<Radio/>} label="No"/>
        </RadioGroup>
      </FormControl>
    </div>
  )
}