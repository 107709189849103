import * as React from 'react';
import { Typography, Container, Paper } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {useEffect} from "react";

export default function FormSubmitted({handleSubmit}) {
  useEffect(() => {
    window.scrollTo(0, 0);
    handleSubmit();
  }, []);

  return (
    <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "16px" }}>
      <Paper elevation={0} style={{ padding: "40px", borderRadius: "10px" }}>
        {/* ✅ Check Icon */}
        <CheckCircleIcon style={{ fontSize: 80, color: "green" }} />

        {/* Confirmation Message */}
        <Typography variant="h5" style={{ fontWeight: "bold", marginTop: "8px" }}>
          Response Submitted Successfully!
        </Typography>
        <Typography variant="body1" style={{ marginTop: "10px", color: "#555" }}>
          Thank you for submitting the form. Your request has been received.
        </Typography>
        <Typography variant="body2" style={{ marginTop: "10px", color: "#777" }}>
          You will receive an email or call with further details.
        </Typography>
      </Paper>
    </Container>
  );
}
