import * as React from 'react';
import { Grid, Typography } from "@material-ui/core";

export default function SubmitForm({ formData }) {

  return (
    <div style={{ textAlign: "left" }}>
      <Grid container spacing={2}>
        {Object.entries(formData).map(([question, answer], index) => (
          <Grid item xs={12} key={index}>
            <Typography variant="body1" style={{ fontWeight: 500 }}>
              {question}:
            </Typography>
            {Array.isArray(answer) ? (
              answer.map((line, i) => (
                <Typography
                  key={i}
                  variant="body2"
                  style={{color: '#4301ce', fontWeight: 'bold', whiteSpace: 'pre-wrap' }}
                >
                  {line}
                </Typography>
              ))
            ) : (
              <Typography
                variant="body2"
                style={{color: '#4301ce', fontWeight: 'bold' }}
              >
                {answer || "Not provided"}
              </Typography>
            )}
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
