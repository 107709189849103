import * as React from 'react';
import Button from "@material-ui/core/Button";
import { Helmet } from "react-helmet";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from '@material-ui/core/Grid';
import { Link } from "react-router-dom";
import Container from "@material-ui/core/Container";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Typography from "@material-ui/core/Typography";
import MuiAlert from '@material-ui/lab/Alert';
import { useState, useEffect } from "react";
import SubmitForm from "./helpers/SubmitForm";
import { trackPromise } from "react-promise-tracker";
import Q1 from "./helpers/Q1";
import Q2 from "./helpers/Q2";
import PaymentQ from "./helpers/PaymentQ";
import DynamicMenuOptions from "./helpers/Q3MenuOptions";
import DynamicGuestForm from "./helpers/DynamicGuestForm";
import FormSubmitted from "./helpers/FormSubmited";
import masonLogo from "./../../assets/static/images/5612-logo.png";
import credentials from './../../environments/credentials'

const q = {
  1: 'Are you attending regular lodge?',
  2: 'Will you be having dinner?',
  3: 'Select your option for starter?',
  4: 'Select your option for mains?',
  5: 'Select your option for dessert?',
  6: 'Are you bringing any guests?',
  7: 'Please select payment option',
  8: 'Summary of your answers',
}

function Alert(props) {
  return <MuiAlert {...props} />;
}

export default function RegularLodgeAttForm() {
  const [uid, setUid] = useState(null);
  const [value, setValue] = React.useState('');
  const [value2, setValue2] = React.useState('');
  const [selectedStarter, setSelectedStarter] = React.useState('');
  const [selectedMain, setSelectedMain] = React.useState('');
  const [selectedDessert, setSelectedDessert] = React.useState('');
  const [dinnerDate, setDinnerDate] = React.useState('');

  // const [guestOption, setGuestOption] = React.useState('');
  const [numGuests, setNumGuests] = useState(0);
  const [guestDetails, setGuestDetails] = useState([]);
  const [value5, setValue5] = React.useState('');
  const [paymentOption, setPaymentOption] = React.useState('');
  const [error, setError] = React.useState(false);
  const [branchEnd, setBranchEnd] = React.useState(false);
  const [menuOptions, setMenuOptions] = useState([]);
  const [lastStep, setLastStep] = React.useState(1);
  const [activeStep, setActiveStep] = React.useState(1);
  const [formSubmitted, setFormSubmitted] = React.useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const uidValue = urlParams.get('uid');
    setUid(uidValue);

    const fetchMenuOptions = async () => {
      try {
        const response = await fetch(`${credentials.COMFY_URL}/masonic-menu-info/`);
        const data = await response.json();
        setMenuOptions(data['data']);
        setDinnerDate(data['data'][0]['Week Of']);
      } catch (error) {
        console.error("Error fetching menu options:", error);
      }
    };

  fetchMenuOptions(); // Call the function inside useEffect
  }, []);


  const handleNext = (e) => {
    if (activeStep === 8){
      setActiveStep(activeStep + 1);
      setFormSubmitted(true);
      return;
    }
    const stepValueDict = {
      1: value,
      2: value2,
      3: selectedStarter,
      4: selectedMain,
      5: selectedDessert,
      6: numGuests
    }
    const stepValue = stepValueDict[activeStep];

    if (branchEnd) {
      setActiveStep(8);
      return;
    }

    if (stepValue === '') {
      setError(true);
    } else if (activeStep === 8) {
      setFormSubmitted(true);
    } else {
      setError(false);
      setActiveStep(activeStep + 1);
    }
  };

  const handleSubmit = () => {
    const userFormDetails = {
      [q[1]]: value,
      [q[2]]: value2,
      [q[3]]: selectedStarter,
      [q[4]]: selectedMain,
      [q[5]]: selectedDessert,
      [q[6]]: numGuests,
      [q[7]]: paymentOption,
      "uid": uid,
      "Guest Details": guestDetails,
      service: 'mason-attendance',
      siteID: 'beeframeworks'
    };
    const postURL = `${credentials.COMFY_URL}/masonic-menu-info/`

    trackPromise(
      fetch(postURL, {
        method: 'POST',
        body: JSON.stringify(userFormDetails),
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        if (response.status === 200) {
          setValue('');
          setValue2('');
          setValue5('');
        }
        return response;
      })
    );
  };

  const handleBack = () => {
    if (branchEnd) {
      setActiveStep(lastStep);
    } else {
      setActiveStep(activeStep - 1);
    }
    setBranchEnd(false);
  };

  const handleQChange = (event, step) => {
    switch (step) {
      case 1:
        setValue(event.target.value);
        if (event.target.value === 'No') {
          setLastStep(step);
          setBranchEnd(true);
          // Reset all subsequent answers
          setValue2('');
          setSelectedStarter('');
          setSelectedMain('');
          setSelectedDessert('');
          setNumGuests(0);
          setGuestDetails([]);
        } else {
          setBranchEnd(false);
        }
        break;
      case 2:
        setValue2(event.target.value);
        if (event.target.value === 'No') {
          setLastStep(step);
          setBranchEnd(true);
          // Reset only food-related answers & guest info
          setSelectedStarter('');
          setSelectedMain('');
          setSelectedDessert('');
          setNumGuests(0);
          setGuestDetails([]);
        } else {
          setBranchEnd(false);
        }
        break;
      case 3:
        setSelectedStarter(event.target.value);
        break
      case 4:
        setSelectedMain(event.target.value);
        break;
      case 5:
        setSelectedDessert(event.target.value);
        break;
      case 7:
        setPaymentOption(event.target.value);
        break;
      default:
        setValue(event.target.value);
        break;
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta name="description" content="Masonic Hall" />
        <title>Lodge Meetings</title>
      </Helmet>
      <CssBaseline />
      <main>
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Lodge Meetings</BreadcrumbItem>
          </Breadcrumb>
          <Container maxWidth="md" style={{ paddingTop: "40px"}}>
            <div
              style={{
                textAlign: "center",
                backgroundColor: "#124e8f",
                marginBottom: "40px"
            }}>
              <img src={masonLogo} alt="Masonic Logo" />
            </div>
            <Typography
              variant="h5"
              align="left"
              gutterBottom
              style={{ fontWeight: "600",  paddingBottom: "16px" }}

            >
              {q[activeStep]}
            </Typography>

            {error && (
              <div style={{ padding: "16px 0px" }}>
                <Alert severity="error">Please provide an answer.</Alert>
              </div>
            )}

            {activeStep === 1 &&
              (<Q1
                handleQ={handleQChange}
                q1Value={value}
                dateLabel={dinnerDate}
              />)}
            {activeStep === 2 && <Q2 handleQ={handleQChange} q2Value={value2} />}
            {activeStep === 3 && (
              <DynamicMenuOptions
                label="Select Your Starter"
                options={menuOptions.map(option => option['Starter Option'])}
                selectedValue={selectedStarter}
                handleSelection={handleQChange}
                step={3}
              />
            )}

            {activeStep === 4 && (
              <DynamicMenuOptions
                label="Select Your Main"
                options={menuOptions.map(option => option['Mains Options'])}
                selectedValue={selectedMain}
                handleSelection={handleQChange}
                step={4}
              />
            )}

            {activeStep === 5 && (
              <DynamicMenuOptions
                label="Select Your Dessert"
                options={menuOptions.map(option => option['Desert Options'])}
                selectedValue={selectedDessert}
                handleSelection={handleQChange}
                step={5}
              />
            )}

            {activeStep === 6 && (
              <DynamicGuestForm
                numGuests={numGuests}
                setNumGuests={setNumGuests}
                guestDetails={guestDetails}
                setGuestDetails={setGuestDetails}
                menuOptions={menuOptions}
              />
            )}
            {/*handlePaymentQ, paymentQValue*/}

            {activeStep === 7 && <PaymentQ handlePaymentQ={handleQChange} paymentQValue={paymentOption} />}

            {activeStep === 8 && (
              <SubmitForm  formData={Object.fromEntries(
                Object.entries({
                  [q[1]]: value, // Answer for attending
                  [q[2]]: value2, // Answer for dinner
                  [q[3]]: selectedStarter || null,
                  [q[4]]: selectedMain || null,
                  [q[5]]: selectedDessert || null,
                  [q[7]]: paymentOption || null,
                  ...(numGuests > 0
                    ? {
                        [q[6]]: `${numGuests} Guest(s)`,
                        "Guest Details": guestDetails.map((g, i) =>
                          `Guest ${i + 1}: ${g.name} (Rank: ${g.rank}) - Meal Option: ${g.providingOption ? "Yes" : "No"}`
                        )
                      }
                    : {} // If no guests, don't include these fields
                  )
                }).filter(([_, answer]) => answer !== null && answer !== "")
              )} />
            )}


            {activeStep === 9 && (
              <FormSubmitted handleSubmit={handleSubmit} />
            )}

            {!formSubmitted && (
              <Grid
                container
                spacing={2}
                style={{ marginTop: "56px", marginBottom: "64px"  }}

              >
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleBack}
                    disabled={activeStep === 1}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={(e) => handleNext(e)}
                  >
                    {activeStep === 8 ? 'Submit' : 'Continue'}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Container>
        </div>
      </main>
    </React.Fragment>
  );
}
